import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldCurrencyInput } from '../../../components';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from './OfferForm.module.css';
import IconPromote from '../../../components/IconPromote/IconPromote';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';

const { Money } = sdkTypes;

const OfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        isOffersPage,
        invalid,
        sendButtonText,
      } = fieldRenderProps;

      const offPriceLabel = intl.formatMessage(
        {
          id: 'OfferForm.offPriceLabel',
        },
        { authorDisplayName }
      );

      const offPriceRequiredMessage = intl.formatMessage({
        id: 'OfferForm.offPriceRequired',
      });
      const offPriceRequired = validators.requiredAndNonEmptyString(offPriceRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );

      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          {!isOffersPage ? (
            <>
              <IconPromote className={css.icon} />
              <h2 className={css.heading}>
                <FormattedMessage
                  id="OfferForm.heading"
                  values={{ listingTitle, authorDisplayName }}
                />
              </h2>
            </>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.input}
            label={isOffersPage ? '' : offPriceLabel}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          <div className={css.actionBtns}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="OfferForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitBtn}
              type="submit"
              inProgress={submitInProgress}
              disabled={invalid}
            >
              {sendButtonText || <FormattedMessage id="OfferForm.submitButtonText" />}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

OfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

OfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OfferForm = compose(injectIntl)(OfferFormComponent);

OfferForm.displayName = 'OfferForm';

export default OfferForm;
