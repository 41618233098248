import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard, Modal } from '../../components';
import EnquiryForm from './EnquiryForm/EnquiryForm';

import css from './ListingPage.module.css';
import OfferForm from './OfferForm/OfferForm';
import { getStoreName } from '../../util/dataExtractors';

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    onManageDisableScrolling,
    enquiryType,
    currentAuthor,
  } = props;

  if (!listing.author) {
    return null;
  }

  const form =
    enquiryType === 'offer' ? (
      <OfferForm
        className={css.enquiryForm}
        submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
        listingTitle={title}
        authorDisplayName={getStoreName(currentAuthor) || authorDisplayName}
        sendEnquiryError={sendEnquiryError}
        onSubmit={value => {
          const { price } = value;
          const offerPrice = price?.amount / 100;
          onSubmitEnquiry({ offerPrice });
        }}
        inProgress={sendEnquiryInProgress}
      />
    ) : (
      <EnquiryForm
        className={css.enquiryForm}
        submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
        listingTitle={title}
        authorDisplayName={authorDisplayName}
        sendEnquiryError={sendEnquiryError}
        onSubmit={onSubmitEnquiry}
        inProgress={sendEnquiryInProgress}
      />
    );

  return (
    <div id="author" className={css.sectionAuthor}>
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {form}
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
