import React from 'react';
import css from './ListingPage.module.css';

const SectionDetailsMaybe = ({ publicData, hasVariants }) => {
  // This helper checks if the data is available and returns a UI element, or null otherwise
  const renderDetailItem = (label, value) => {
    return value ? (
      <li className={css.detailsRow}>
        <span className={css.detailLabel}>{label}</span>
        <span>{value}</span>
      </li>
    ) : null;
  };

  // Ensure there's publicData to render, otherwise return nothing
  if (!publicData) return <></>;

  return (
    <div className={css.sectionDetails}>
      <ul className={css.details}>
        {renderDetailItem('Brand', publicData.brandOption?.label)}
        {renderDetailItem('Color', publicData.colorOption?.label)}
        {renderDetailItem('Category', publicData.categoryOption?.label)}
        {renderDetailItem('Condition', publicData.conditionOption?.label)}
        {!hasVariants && renderDetailItem('Size', publicData.sizeOption?.label)}
        {renderDetailItem('Type', publicData.typeOption?.label)}
      </ul>
    </div>
  );
};

export default SectionDetailsMaybe;
