import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import { IconHeart, IconHeartFilled, IconShare } from '../../components';
import IconMessage from '../../components/IconMessage/IconMessage';
import IconBag from '../../components/IconBag/IconBag';
import IconBagFilled from '../../components/IconBagFilled/IconBagFilled';

import css from './ListingPage.module.css';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMyContext } from '../../contexts/StateHolder';
import { getBookmarks, getWishlist, getLikes, isArrayLength } from '../../util/dataExtractors';
import { isWindowDefined } from '../../util/cartHelpers';
import { GOOGLE_TAGS } from '../../config/enums';
import { pushEventToDataLayer } from '../../util/gtm';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { updateSave } from '../../ducks/user.duck';
import { addStoreProductMailChimp, removeStoreProductMailChimp } from '../../ducks/Global.duck';
import { createSlug } from '../../util/urlHelpers';

const SectionDescriptionMaybe = props => {
  const {
    listingTitle,
    showContactUser,
    onContactUser,
    isOwnListing,
    onOpenShareModal = () => {},
    currentListing,
    currentUser,
    isAuthenticated,
    onUpdateProfile,
    shouldHideBookingPanel = false,
    saveCountBySeller,
    images,
    ensuredAuthor,
    selectedSize,
  } = props;

  let { id } = props;

  const { title = '', price = {}, publicData = {} } = currentListing?.attributes || {};
  const slug = title && createSlug(title);

  const variantListing =
    isArrayLength(publicData?.sizes) &&
    publicData?.sizes?.find(s => s?.size?.values === selectedSize);

  if (variantListing?.id) {
    id = variantListing.id;
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const { setCartItems } = useMyContext();
  const [cacheTagValues, setCacheTagValues] = useState(saveCountBySeller);

  useEffect(() => {
    setCacheTagValues(saveCountBySeller);
  }, [saveCountBySeller]);

  const wishlist = getWishlist(currentUser);
  const likes = getLikes(currentListing);

  let localBookmarks =
    isWindowDefined() &&
    window.localStorage.getItem('localBookmarks') &&
    window.localStorage.getItem('localBookmarks').length > 0
      ? window.localStorage.getItem('localBookmarks')
      : [];

  if (typeof localBookmarks === 'string') {
    localBookmarks = isWindowDefined() && JSON.parse(window.localStorage.getItem('localBookmarks'));
  }

  const bookmarks = isAuthenticated ? getBookmarks(currentUser) : localBookmarks;
  const productPrice = price && price.amount / 100;

  const tagParams = {
    item_id: id,
    item_name: title,
    price: productPrice,
    quantity: 1,
    index: (bookmarks?.length || 0) + 1,
    item_brand: publicData?.brand,
    item_category: publicData?.category,
    item_category2: publicData?.condition,
    item_category3: publicData?.color,
    item_category4: publicData?.size,
    item_variant: publicData?.sku || '',
  };

  const onUpdateCurrentUser = (array, isLikes = false, index) => {
    pushEventToDataLayer(
      tagParams,
      index > -1 ? GOOGLE_TAGS.REMOVE_FROM_CART : GOOGLE_TAGS.ADD_TO_CART
    );
    const params = isLikes
      ? { wishlist: Array.from(new Set(array)) }
      : { bookmarks: Array.from(new Set(array)) };
    onUpdateProfile({
      publicData: { ...params },
    });
  };

  const productParams = {
    id,
    title: title,
    variants: [
      {
        id,
        title: title,
        url: `www.tutulist.com/l/${slug}/${id}`,
        price: productPrice,
        image_url: isArrayLength(images) && images[0],
      },
    ],
    url: `www.tutulist.com/l/${slug}/${id}`,
    vendor: ensuredAuthor?.attributes?.profile?.displayName,
    image_url: isArrayLength(images) && images[0],
  };

  const isSyncedWithMC = publicData?.isSyncedWithMC;

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" values={{ listingTitle }} />
      </h2>
      {saveCountBySeller ? (
        <div className={css.cartMessage}>
          <IconBagFilled />
          <p>
            {' '}
            <FormattedMessage
              id={
                cacheTagValues > 1
                  ? 'ListingPage.saveCountBySellerPeople'
                  : 'ListingPage.saveCountBySeller'
              }
              values={{ saveCountBySeller: cacheTagValues }}
            />
          </p>
        </div>
      ) : null}
      <div className={css.messageSec}>
        <span
          className={css.bookmark}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            if (!isAuthenticated && e && id) {
              const routes = routeConfiguration();
              history.push(createResourceLocatorString('LoginPage', routes, {}, {}));
            } else {
              const index = wishlist && wishlist.findIndex(b => b == id);
              const likesIndex =
                Array.isArray(likes) && likes.findIndex(l => l == currentUser?.id?.uuid);

              if (id) {
                // To save in listing to show likes
                if (likesIndex > -1) {
                  likes && likes.splice(likesIndex, 1);
                  const filteredLikes = Array.from(new Set(likes));
                  dispatch(updateSave({ likes: filteredLikes, listingId: id }));
                } else {
                  Array.isArray(likes) && likes.push(currentUser?.id?.uuid);
                  const filteredLikes = Array.from(new Set(likes));
                  dispatch(updateSave({ likes: filteredLikes, listingId: id }));
                }

                // To save in currentUser as favourites
                if (index > -1) {
                  wishlist && wishlist.splice(index, 1);
                  const removedwishlist = Array.from(new Set(wishlist));
                  onUpdateCurrentUser(removedwishlist, true, index);
                } else {
                  wishlist.push(id);
                  onUpdateCurrentUser(Array.from(new Set(wishlist)), true, index);
                }
              }
            }
          }}
        >
          {wishlist && wishlist.findIndex(e => e == id) > -1 ? <IconHeartFilled /> : <IconHeart />}
        </span>
        {shouldHideBookingPanel ? null : showContactUser ? (
          <span className={css.icons} onClick={onContactUser}>
            <IconMessage />
          </span>
        ) : null}

        {shouldHideBookingPanel || isOwnListing ? null : (
          <span
            className={css.bookmark}
            onClick={e => {
              if (isAuthenticated) {
                e.preventDefault();
                e.stopPropagation();
                if (id) {
                  const index = bookmarks && bookmarks.findIndex(b => b == id);
                  if (index > -1) {
                    bookmarks && bookmarks.splice(index, 1);
                    setCacheTagValues(cacheTagValues - 1);
                    onUpdateCurrentUser(bookmarks, false, index);
                    dispatch(
                      removeStoreProductMailChimp({
                        productId: id,
                        currentUser,
                        isSyncedWithMC,
                      })
                    );
                  } else {
                    bookmarks.push(id);
                    setCacheTagValues(cacheTagValues + 1);
                    onUpdateCurrentUser(Array.from(new Set(bookmarks)), false, index);
                    dispatch(
                      addStoreProductMailChimp({ productParams, currentUser, isSyncedWithMC })
                    );
                  }
                }
              } else {
                const localIndex = localBookmarks && localBookmarks.findIndex(b => b == id);
                if (localIndex > -1) {
                  localBookmarks && localBookmarks.splice(localIndex, 1);
                  const removedBookmarks = Array.from(new Set(localBookmarks));
                  isWindowDefined() &&
                    window.localStorage.setItem('localBookmarks', JSON.stringify(removedBookmarks));
                  setCartItems(removedBookmarks);
                } else {
                  localBookmarks.push(id);
                  const addedBookmarks = Array.from(new Set(localBookmarks));
                  isWindowDefined() &&
                    window.localStorage.setItem('localBookmarks', JSON.stringify(addedBookmarks));
                  setCartItems(addedBookmarks);
                }
              }
            }}
          >
            {bookmarks && bookmarks.findIndex(e => e == id) > -1 ? <IconBagFilled /> : <IconBag />}
          </span>
        )}
        <span onClick={() => onOpenShareModal(true)} className={css.socialSharing}>
          <IconShare />
        </span>
        <span className={css.likes}> {`${Array.isArray(likes) && likes.length} likes`}</span>
      </div>
    </div>
  );
};

export default SectionDescriptionMaybe;
